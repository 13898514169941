<template>
    <div class="contenedor-profile d-flex align-items-center justify-content-start flex-column">
            <div class="fondo-degradado-profile"></div>
            <h1 class="titulo-profile">Configuraciones</h1>
            <div class="contenedor-form d-flex align-items-center justify-content-start flex-column">
                <div class="col-12" v-if="bulLoader === false">
                    <div class="row">
                        <div class="col-6">
                            <form class="d-flex align-items-center justify-content-center flex-column" v-on:submit.prevent>
                                <div class="d-flex flex-row align-items-center justify-content-start contenedor-inputs">
                                    <label for="nombre" class="label">Nombre:</label>
                                    <input type="text" id="nombre" class="input" v-model="nombre" />  
                                </div>
                                <div class="d-flex flex-row align-items-center justify-content-start contenedor-inputs">
                                    <label for="apellido" class="label">Apellidos:</label>
                                    <input type="text" id="apellido" class="input" v-model="apellido" />  
                                </div>
                                <div class="d-flex flex-row align-items-center justify-content-start contenedor-inputs">
                                    <label for="correo" class="label">Correo:</label>
                                    <input type="text" id="correo" class="input" v-model="correo" />  
                                </div>
                                <div class="d-flex flex-row align-items-center justify-content-start contenedor-inputs">
                                    <label for="role" class="label">Role:</label>
                                    <select name="" id="role" class="input d-flex" v-model="roless">
                                            <option value="" ></option>
                                            <option v-for="role in roles" v-bind:key="role.id" v-bind:value="role">{{role}}</option>
                                    </select>
                                </div>
                                <div class="d-flex flex-row align-items-center justify-content-start contenedor-inputs">
                                    <label for="pais" class="label">País:</label>
                                    <select name="" id="pais" class="input d-flex" v-model="paiss">
                                            <option value="" ></option>
                                            <option v-for="pais in paises" v-bind:key="pais.id" v-bind:value="pais">{{pais}}</option>
                                    </select>
                                </div>
                                <div class="d-flex flex-row align-items-center justify-content-start contenedor-inputs">
                                    <label for="ciudad" class="label">Ciudad:</label>
                                    <input type="text" id="ciudad" class="input" v-model="ciudad" />  
                                </div>
                                <div class="d-flex flex-row align-items-center justify-content-start contenedor-inputs">
                                    <label for="referenciado" class="label">Referenciado:</label>
                                    <input type="text" id="referenciado" class="input" v-model="referenciado" />  
                                </div>
                            </form>
                        </div>
                        <div class="col-6 d-flex align-items-center justify-content-center flex-column">
                            <h1 class="titulo-foto">Foto de perfil</h1>
                            <div class="cuadrado-perfil d-flex align-items-center justify-content-center">
                                <div class="circulo-perfil d-flex align-items-center justify-content-center">
                                    <svg class="cruz-iniciar" viewBox="0 0 176 173" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="bulFoto === false">
                                        <g filter="url(#filter0_f_481_2)">
                                        <path d="M72 102.5V162H104.5V102.5H165V70H104.5V11H72V70H11V102.5H72Z" stroke="black"/>
                                        </g>
                                        <path d="M72 102.5V162H104.5V102.5H165V70H104.5V11H72V70H11V102.5H72Z" fill="url(#paint0_linear_481_2)" stroke="black"/>
                                        <defs>
                                        <filter id="filter0_f_481_2" x="0.5" y="0.5" width="175" height="172" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                        <feGaussianBlur stdDeviation="5" result="effect1_foregroundBlur_481_2"/>
                                        </filter>
                                        <linearGradient id="paint0_linear_481_2" x1="11" y1="11" x2="165" y2="162" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="white"/>
                                        <stop offset="1" stop-color="white" stop-opacity="0"/>
                                        </linearGradient>
                                        </defs>
                                    </svg>
                                    <div class="imagen-perfil" :style="`height:${alturaImagen}%; width:${anchoImagen}%; margin-top:${topImagen}%; margin-left:${izquierdaImagen}%; background-image:url(${urlImagen}); background-size:100% 100%; background-repeat: no-repeat; background-position:center;`" v-if="bulFoto === true"></div>
                                </div>
                            </div>
                            <div class="d-flex flex-row">
                                <p class="pie-de-foto" @click="poderCambiarFoto">Cambiar foto</p>
                                <p class="pie-de-foto2" @click="poderEditarFoto" v-if="urlImagen !== null">Editar posicion</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex justify-content-center align-items-center flex-column">
                    <button v-if="bulLoader === false" class="button-configuracion2 d-flex justify-content-center align-items-center" @click="editarProfile($event)">Editar datos</button>
                    <div v-if="bulLoader === false" class="d-flex flex-column justify-content-center align-items-center contenedor-cambioPassword">
                        <div class="d-flex flex-column align-items-start justify-content-start contenedor-inputs2">
                            <label for="password1" class="label2">Nueva contraseña:</label>
                            <input type="password" id="password1" class="input2" v-model="nuevaPassword" />  
                        </div>
                    </div>
                    <div v-if="bulLoader === false" class="d-flex flex-column justify-content-center align-items-center contenedor-cambioPassword">
                        <div class="d-flex flex-column align-items-start justify-content-start contenedor-inputs2">
                            <label for="password2" class="label2">Confimar contraseña:</label>
                            <input type="password" id="password2" class="input2" v-model="confirmaPassword" />  
                        </div>
                    </div>
                    <button v-if="bulLoader === false" class="button-configuracion2 d-flex justify-content-center align-items-center" @click="cambiarProfile($event)">Cambiar contraseña</button>
                </div> 
            </div>
            <div class="contenedor-imagen d-flex align-items-center flex-column" v-if="abrirInterfazCambiarFoto === true">
                <div class="x-nuevo" @click="poderCambiarFoto"></div>
                <div class="cuadrado-perfil2 d-flex align-items-center justify-content-center" @mousemove="posicionamientoImagen($event)" @mouseup="posicionamientoImagen($event)" v-if="subirImagenBul === false">
                    <div class="circulo-perfil2 d-flex align-items-center justify-content-center" @mouseup="posicionamientoImagen($event)">
                        <div draggable="false" @dragstart="posicionamientoImagen($event)" @mouseup="posicionamientoImagen($event)" @mousedown="posicionamientoImagen($event)" @mouseleave="posicionamientoImagen($event)" class="imagen-cambio-perfil" :style="`height:${alturaImagen}%; width:${anchoImagen}%; margin-top:${topImagen}%; margin-left:${izquierdaImagen}%; background-image:url(${urlImagen}); background-size:100% 100%; background-repeat: no-repeat; background-position:center;`" v-if="bulFoto === true"/>
                    </div>
                </div>
                <div class="cuadrado-perfil3 d-flex align-items-center justify-content-center" v-if="subirImagenBul === true">
                    <div class="circulo-perfil2 d-flex align-items-center justify-content-center" v-if="archivoSubido === false">
                        <div draggable="false" class="imagen-cambio-perfil2" :style="`height:100%; width:100%; margin-top:0%; margin-left:0%; background-size:20% 20%; background-repeat: no-repeat; background-position:center;`" />
                    </div>
                    <div class="circulo-perfil2 d-flex align-items-center justify-content-center flex-column" v-if="archivoSubido === true">
                        <div class="carpeta-lista"></div>
                        <p class="text-white text-center">{{perfil["name"]}}</p>
                    </div>
                </div>
                <div class="ancho-y-alto d-flex flex-row" v-if="subirImagenBul === false">
                    <div class="d-flex flex-column">
                        <label for="ancho" class="label3">Ancho</label>
                        <input type="number" id="ancho" class="input3" v-model="anchoImagen" />
                    </div>
                    <div class="d-flex flex-column margin-input">
                        <label for="alto" class="label3">Alto</label>
                        <input type="number" id="alto" class="input3" v-model="alturaImagen" />  
                    </div>
                </div>
                <div class="contenedor-file">
                    <input type="file" class="button-profile2 d-flex justify-content-center align-items-center" @change="guardarImagen($event)" v-if="subirImagenBul === true">
                </div>
                <button class="button-configuracion d-flex justify-content-center align-items-center" @click="subirImagen($event)" v-if="subirImagenBul === true">Subir imagen</button>
                <button class="button-configuracion d-flex justify-content-center align-items-center" @click="guardarParametrosImagen($event)" v-if="subirImagenBul === false">Guardar cambios</button>
            </div>
            <div class="contenedor-errores d-flex justify-content-center align-items-start" v-if="bulError === true">
                <div class="loader" v-if="bulLoader === true">
                    <div class="caja"><div class="caja2"></div></div>
                </div>
                <div class="error-profile d-flex justify-content-center align-items-center" v-if="bul === true">
                    <div><p>{{mensaje}}</p></div>
                </div>  
                <div class="correcto-profile d-flex justify-content-center align-items-center" v-if="bul1 === true">
                    <div><p>{{mensaje}}</p></div>
                </div>
            </div>

    </div>
</template>

<script>
    import {mapState, mapActions} from "vuex"
    import axios from 'axios'

    export default {
        name: "App",
        data(){
            return{
                //control de respuestas de la api
                bul:false,
                bul1:false,
                mensaje:'',

                //informacion formulario
                paises: this.paises = [
                    "Afghanistan",
                    "Albania",
                    "Algeria",
                    "American Samoa",
                    "Andorra",
                    "Angola",
                    "Anguilla",
                    "Antarctica",
                    "Antigua and Barbuda",
                    "Argentina",
                    "Armenia",
                    "Aruba",
                    "Australia",
                    "Austria",
                    "Azerbaijan",
                    "Bahamas",
                    "Bahrain",
                    "Bangladesh",
                    "Barbados",
                    "Belarus",
                    "Belgium",
                    "Belize",
                    "Benin",
                    "Bermuda",
                    "Bhutan",
                    "Bolivia",
                    "Botswana",
                    "Bouvet Island",
                    "Brazil",
                    "Brunei Darussalam",
                    "Bulgaria",
                    "Burkina Faso",
                    "Burundi",
                    "Cabo Verde",
                    "Cambodia",
                    "Cameroon",
                    "Canada",
                    "Cayman Islands",
                    "Chad",
                    "Chile",
                    "China",
                    "Christmas Island",
                    "Cocos",
                    "Colombia",
                    "Comoros",
                    "Congo",
                    "Cook Islands",
                    "Costa Rica",
                    "Croatia",
                    "Cuba",
                    "Curaçao",
                    "Cyprus",
                    "Czechia",
                    "Côte d'Ivoire",
                    "Denmark",
                    "Djibouti",
                    "Dominica",
                    "Dominican Republic",
                    "Ecuador",
                    "Egypt",
                    "El Salvador",
                    "Equatorial Guinea",
                    "Eritrea",
                    "Estonia",
                    "Eswatini",
                    "Ethiopia",
                    "Falkland Islands",
                    "Faroe Islands",
                    "Fiji",
                    "Finland",
                    "France",
                    "French Guiana",
                    "French Polynesia",
                    "Gabon",
                    "Gambia",
                    "Georgia",
                    "Germany",
                    "Ghana",
                    "Gibraltar",
                    "Greece",
                    "Greenland",
                    "Grenada",
                    "Guadeloupe",
                    "Guam",
                    "Guatemala",
                    "Guernsey",
                    "Guinea",
                    "Guinea-Bissau",
                    "Guyana",
                    "Haiti",
                    "Holy See",
                    "Honduras",
                    "Hong Kong",
                    "Hungary",
                    "Iceland",
                    "India",
                    "Indonesia",
                    "Iran",
                    "Iraq",
                    "Ireland",
                    "Isle of Man",
                    "Israel",
                    "Italy",
                    "Jamaica",
                    "Japan",
                    "Jersey",
                    "Jordan",
                    "Kazakhstan",
                    "Kenya",
                    "Kiribati",
                    "Korea",
                    "Korea",
                    "Kuwait",
                    "Kyrgyzstan",
                    "Latvia",
                    "Lebanon",
                    "Lesotho",
                    "Liberia",
                    "Libya",
                    "Liechtenstein",
                    "Lithuania",
                    "Luxembourg",
                    "Macao",
                    "Madagascar",
                    "Malawi",
                    "Malaysia",
                    "Maldives",
                    "Mali",
                    "Malta",
                    "Marshall Islands",
                    "Martinique",
                    "Mauritania",
                    "Mauritius",
                    "Mayotte",
                    "Mexico",
                    "Micronesia",
                    "Moldova",
                    "Monaco",
                    "Mongolia",
                    "Montenegro",
                    "Montserrat",
                    "Morocco",
                    "Mozambique",
                    "Myanmar",
                    "Namibia",
                    "Nauru",
                    "Nepal",
                    "Netherlands",
                    "New Caledonia",
                    "New Zealand",
                    "Nicaragua",
                    "Niger",
                    "Nigeria",
                    "Niue",
                    "Norfolk Island",
                    "Norway",
                    "Oman",
                    "Pakistan",
                    "Palau",
                    "Palestine, State of",
                    "Panama",
                    "Papua New Guinea",
                    "Paraguay",
                    "Peru",
                    "Philippines",
                    "Pitcairn",
                    "Poland",
                    "Portugal",
                    "Puerto Rico",
                    "Qatar",
                    "Romania",
                    "Russian Federation",
                    "Rwanda",
                    "Réunion",
                    "Saint Barthélemy",
                    "Saint Helena",
                    "Saint Lucia",
                    "Saint Martin",
                    "Samoa",
                    "San Marino",
                    "Saudi Arabia",
                    "Senegal",
                    "Serbia",
                    "Seychelles",
                    "Sierra Leone",
                    "Singapore",
                    "Sint Maarten",
                    "Slovakia",
                    "Slovenia",
                    "Solomon Islands",
                    "Somalia",
                    "South Africa",
                    "South Sudan",
                    "Spain",
                    "Sri Lanka",
                    "Sudan ",
                    "Suriname",
                    "Svalbard and Jan Mayen",
                    "Sweden",
                    "Switzerland",
                    "Syrian Arab Republic",
                    "Taiwan",
                    "Tajikistan",
                    "Tanzania",
                    "Thailand",
                    "Timor-Leste",
                    "Togo",
                    "Tokelau",
                    "Tonga",
                    "Trinidad and Tobago",
                    "Tunisia",
                    "Turkey",
                    "Turkmenistan",
                    "Tuvalu",
                    "Uganda",
                    "Ukraine",
                    "United Arab Emirates",
                    "United Kingdom",
                    "United States of America",
                    "Uruguay",
                    "Uzbekistan",
                    "Vanuatu",
                    "Venezuela",
                    "Viet Nam",
                    "Virgin Islands",
                    "Virgin Islands",
                    "Wallis and Futuna",
                    "Western Sahara",
                    "Yemen",
                    "Zambia",
                    "Zimbabwe",
                    "Åland Islands"
                ],
                roles: this.role = [
                    "Contador",
                    "Programador",
                    "Emprendedor",
                    "Trader",
                    "Inversor",
                    "Otros"
                ],
                paiss: '',
                roless: '',
                nombre: '',
                apellido: '',
                correo: '',
                ciudad: '',
                referenciado: '',
                bulLoader: false,
                bulError:false,
                password1:'',
                password2:'',
                bulFoto:false,
                nuevaPassword:'',
                confirmaPassword:'',
                urlImagen:'',
                perfil: '',

                //mover imagen
                topImagen: 0,
                izquierdaImagen: 0,
                bulImagenPerfil: false,
                primerValorComparacionX: 0,
                primerValorComparacionY: 0,
                guardadoMovimientoX: 0,
                guardadoMovimientoY: 0,

                //ancho y alto imagen
                alturaImagen: 100,
                anchoImagen: 100,

                //botones
                subirImagenBul: true,
                archivoSubido: false,
                abrirInterfazCambiarFoto: false,

                //carga
                bulCargaImagen: false,
                bulCambioLoader: true
            }
        },

        methods: {

            //guardar parametros de la imagen para saber cuanto ancho o alto va a tener la misma

            async guardarParametrosImagen(e){
                //editar parametros de imagen de perfil
                let userConfiguraciones = await axios.post(`${this.server}/information/editarImagen`, {
                    ancho:this.anchoImagen,
                    alto:this.alturaImagen,
                    izquierda:this.izquierdaImagen,
                    arriba:this.topImagen,
                    token:localStorage.getItem('token')
                });

                //control de errores
                if(userConfiguraciones.data.message === "Usuario editado"){
                    //usuario editado
                    this.swift('Usuario editado con exito',2)
                    setTimeout(() => {
                        window.location.reload()
                        this.bulCambioLoader = true
                    }, 3500);
                }
                if(userConfiguraciones.data.message === "Error en validación"){
                    //se espiro la sesion
                    this.swift('tiempo excedido, vulvete a loguear',1)
                }
            },

            //subir imagen a la apirest

            async subirImagen(e){
                //confirmacion de formulario
                if(this.perfil === ''){
                    this.swift('selecciona una imagen',1)
                    return
                }

                //creamor form para pasarle la nformacion a la api
                const formArchive = new FormData();

                //guardamos informacion aparte de la imagen
                formArchive.append('token', localStorage.getItem('token'));
                if(this.perfil !== ''){
                    formArchive.append('fotoPerfil', this.perfil, this.perfil["name"]);
                }
                //envio a la api
                const form = await axios.post(`${this.server}/files/imagenPerfil`, formArchive, {'Content-Type': 'multipart/form-data'});
                
                //control de errores
                if(form.data.message === 'error'){
                    this.$router.push({path: '/login'})
                    this.swift('Ingresa de nuevo',1)
                    return
                }else if(form.data === 'archivo no permitido'){
                    this.swift('solo se permite formatos jpg, png y jpeg',1)
                    return
                }else if(form.data.message === "The file is so heavy for my service"){
                    this.swift('tamaño de archivo maximo: 2MB',1)
                    return
                }else if(form.data.URLLista !== null){
                    this.urlImagen = form.data.URLLista
                    this.testImage(form.data.URLLista)
                }else{
                    this.swift('Error',2)
                    return
                }
            },

            //funciones que muestra el proceso de carga de la imagen

            testImage(URL) {
                if(this.bulCambioLoader === true){
                    var tester=new Image();
                    tester.onload=this.imageFound;
                    tester.onerror=this.imageNotFound(URL,1);
                    tester.src=URL;
                    if(this.bulCargaImagen === false){
                        this.bulCargaImagen = true
                        this.bulLoader = true
                        this.bulError = true
                    }
                }
            },

            imageFound() {
                this.imageNotFound('',2)
                this.bulCambioLoader = false
                this.loadProfile()
                this.abrirInterfazCambiarFoto = true
                this.subirImagenBul = false
                this.bulLoader = false
                this.bulError = false
                this.bulCargaImagen = false
                window.scroll(0,0)
            },

            imageNotFound(URL,type) {
                let reloj1 = setTimeout(() => {
                    this.testImage(URL)
                }, 500);
                if(type === 2){
                    clearTimeout(reloj1)
                }
            },

            //swift para que aparezca la interfaz de cambiar foto de perfil

            poderCambiarFoto(){
                if(this.abrirInterfazCambiarFoto === false){
                    this.abrirInterfazCambiarFoto = true
                    this.subirImagenBul = true
                    window.scroll(0,0)
                }else{
                    this.abrirInterfazCambiarFoto = false
                    this.bulCambioLoader = false
                    this.loadProfile()
                    window.location.reload()
                }
            },

            //swift para que aparezca la interfaz de editar foto de perfil

            poderEditarFoto(){
                if(this.abrirInterfazCambiarFoto === false){
                    this.abrirInterfazCambiarFoto = true
                    this.subirImagenBul = false
                    window.scroll(0,0)
                }else{
                    this.abrirInterfazCambiarFoto = false
                    this.subirImagenBul = true
                }
            },

            //guardar imagen en variable

            guardarImagen(e){
                this.perfil = e.target.files[0] 
                this.archivoSubido = true
            },

            //algoritmo para posicionar fotos de perfil

            async posicionamientoImagen(e){
                //evitar que se active la funcion drag

                if(e.type === 'dragstart'){
                    e.preventDefault()
                    return false
                }

                //condicional de cuando se mueve y cuando no

                if(e.type === 'mouseup' || e.type === 'mouseleave'){
                    this.bulImagenPerfil = false
                    this.guardadoMovimientoX = this.izquierdaImagen
                    this.guardadoMovimientoY = this.topImagen
                }else if(e.type === 'mousedown'){
                    this.bulImagenPerfil = true
                    this.primerValorComparacionX = (e.x - (350))/2
                    this.primerValorComparacionY = (e.y - (350))/2
                }

                //creacion de movimiento de la imagen siguiendo el mouse al dar click

                if(this.bulImagenPerfil === true && this.izquierdaImagen <= 115 && this.izquierdaImagen >= -115 && this.topImagen <= 115 && this.topImagen >= -115){
                    this.izquierdaImagen = (((e.x - (350))/2)-this.primerValorComparacionX)+this.guardadoMovimientoX
                    this.topImagen = (((e.y - (350))/2)-this.primerValorComparacionY)+this.guardadoMovimientoY
                }

                //limitacion de chocar con paredes

                if(this.bulImagenPerfil === true && this.topImagen > 115 && this.izquierdaImagen <= 115 && this.izquierdaImagen >= -115){
                    if((((e.y - (350))/2)-this.primerValorComparacionY) < 0){
                        this.topImagen = (((e.y - (350))/2)-this.primerValorComparacionY)+this.guardadoMovimientoY
                    }
                    if((((e.x - (350))/2)-this.primerValorComparacionX) < 0){
                        this.izquierdaImagen = (((e.x - (350))/2)-this.primerValorComparacionX)+this.guardadoMovimientoX
                    }
                    if((((e.x - (350))/2)-this.primerValorComparacionX) > 0){
                        this.izquierdaImagen = (((e.x - (350))/2)-this.primerValorComparacionX)+this.guardadoMovimientoX
                    }
                }
                if(this.bulImagenPerfil === true && this.topImagen < -115 && this.izquierdaImagen <= 115 && this.izquierdaImagen >= -115){
                    if((((e.y - (350))/2)-this.primerValorComparacionY) > 0){
                        this.topImagen = (((e.y - (350))/2)-this.primerValorComparacionY)+this.guardadoMovimientoY
                    }
                    if((((e.x - (350))/2)-this.primerValorComparacionX) < 0){
                        this.izquierdaImagen = (((e.x - (350))/2)-this.primerValorComparacionX)+this.guardadoMovimientoX
                    }
                    if((((e.x - (350))/2)-this.primerValorComparacionX) > 0){
                        this.izquierdaImagen = (((e.x - (350))/2)-this.primerValorComparacionX)+this.guardadoMovimientoX
                    }
                }
                if(this.bulImagenPerfil === true && this.izquierdaImagen > 115 && this.topImagen <= 115 && this.topImagen >= -115){
                    if((((e.x - (350))/2)-this.primerValorComparacionX) < 0){
                        this.izquierdaImagen = (((e.x - (350))/2)-this.primerValorComparacionX)+this.guardadoMovimientoX
                    }
                    if((((e.y - (350))/2)-this.primerValorComparacionY) < 0){
                        this.topImagen = (((e.y - (350))/2)-this.primerValorComparacionY)+this.guardadoMovimientoY
                    }
                    if((((e.y - (350))/2)-this.primerValorComparacionY) > 0){
                        this.topImagen = (((e.y - (350))/2)-this.primerValorComparacionY)+this.guardadoMovimientoY
                    }
                }
                if(this.bulImagenPerfil === true && this.izquierdaImagen < -115 && this.topImagen <= 115 && this.topImagen >= -115){
                    if((((e.x - (350))/2)-this.primerValorComparacionX) > 0){
                        this.izquierdaImagen = (((e.x - (350))/2)-this.primerValorComparacionX)+this.guardadoMovimientoX
                    }
                    if((((e.y - (350))/2)-this.primerValorComparacionY) < 0){
                        this.topImagen = (((e.y - (350))/2)-this.primerValorComparacionY)+this.guardadoMovimientoY
                    }
                    if((((e.y - (350))/2)-this.primerValorComparacionY) > 0){
                        this.topImagen = (((e.y - (350))/2)-this.primerValorComparacionY)+this.guardadoMovimientoY
                    }
                }
                if(this.bulImagenPerfil === true && this.topImagen > 115 && this.izquierdaImagen > 115){
                    if((((e.y - (350))/2)-this.primerValorComparacionY) < 0){
                        this.topImagen = (((e.y - (350))/2)-this.primerValorComparacionY)+this.guardadoMovimientoY
                    }
                    if((((e.x - (350))/2)-this.primerValorComparacionX) < 0){
                        this.izquierdaImagen = (((e.x - (350))/2)-this.primerValorComparacionX)+this.guardadoMovimientoX
                    }
                }
                if(this.bulImagenPerfil === true && this.topImagen > 115 && this.izquierdaImagen < -115){
                    if((((e.y - (350))/2)-this.primerValorComparacionY) < 0){
                        this.topImagen = (((e.y - (350))/2)-this.primerValorComparacionY)+this.guardadoMovimientoY
                    }
                    if((((e.x - (350))/2)-this.primerValorComparacionX) > 0){
                        this.izquierdaImagen = (((e.x - (350))/2)-this.primerValorComparacionX)+this.guardadoMovimientoX
                    }
                }
                if(this.bulImagenPerfil === true && this.topImagen < -115 && this.izquierdaImagen > 115){
                    if((((e.y - (350))/2)-this.primerValorComparacionY) > 0){
                        this.topImagen = (((e.y - (350))/2)-this.primerValorComparacionY)+this.guardadoMovimientoY
                    }
                     if((((e.x - (350))/2)-this.primerValorComparacionX) < 0){
                        this.izquierdaImagen = (((e.x - (350))/2)-this.primerValorComparacionX)+this.guardadoMovimientoX
                    }
                }
                if(this.bulImagenPerfil === true && this.topImagen < -115 && this.izquierdaImagen < -115){
                    if((((e.y - (350))/2)-this.primerValorComparacionY) > 0){
                        this.topImagen = (((e.y - (350))/2)-this.primerValorComparacionY)+this.guardadoMovimientoY
                    }
                    if((((e.x - (350))/2)-this.primerValorComparacionX) > 0){
                        this.izquierdaImagen = (((e.x - (350))/2)-this.primerValorComparacionX)+this.guardadoMovimientoX
                    }
                }
            },

            //editar los datos del usuario

            async editarProfile(e){
                e.preventDefault()

                //declaracion de variables
                let comprobadorEmail = /\w+@\w+\.+[a-z]/;

                //qe no exceda el largo 

                this.comprobarLargo(this.nombre.length,`Nombre`)
                this.comprobarLargo(this.apellido.length,`Apellido`)
                this.comprobarLargo(this.ciudad.length,`Ciudad`)

                //comprobar que el referenciado no sea el mismo que el correo de registro

                if(this.correo === this.referenciado){
                    this.swift(`el correo referenciado no puede ser el mismo usado para tu cuenta`, 1)
                    return
                }

                //confirmar que se este ingresando un correo
                if(comprobadorEmail.test(this.correo) === false){
                    this.swift(`Ingresa un correo`, 1)
                    return
                }

                //editar datos del usuario
                let userConfiguraciones = await axios.post(`${this.server}/information/editarProfile`, {
                    pais:this.paiss,
                    role:this.roless,
                    nombre:this.nombre,
                    apellido:this.apellido,
                    correo:this.correo,
                    ciudad:this.ciudad,
                    referenciado:this.referenciado,
                    token:localStorage.getItem('token')
                });

                //control de errores
                if(userConfiguraciones.data.message === "Usuario editado"){
                    //usuario editado
                    this.swift('Usuario editado con exito',2)
                }
                if(userConfiguraciones.data.message === "Error en validación"){
                    //se espiro la sesion
                    this.swift('tiempo excedido, vulvete a loguear',1)
                }
            },

            //funcion comprobadora de largo texto

            comprobarLargo(input,nombre){
                if(input > 30){
                    this.swift(`${nombre} no puede exceder de 30 caracteres`, 1)
                }else{
                    return
                }
            },

            //funcion de cambiar passwrds

            async cambiarProfile() {

                if(this.nuevaPassword.length > 30){
                    //completa los campos
                    this.swift('Contraseña no puede exceder los 30 caracteres',1)
                    return
                }

                if(this.nuevaPassword !== '' && this.confirmaPassword !== ''){
                    if(this.nuevaPassword === this.confirmaPassword){
                        let mensaje = await axios.put(`${this.server}/information/updatepassword`, {
                            contrasenia: this.nuevaPassword,
                            token: localStorage.getItem('token')
                        })
                        if(mensaje.data.message === "hecho!"){
                            //password cambiada
                            this.swift('contraseña cambiada con exito',2)
                        }
                        if(mensaje.data.message === "Error en validación"){
                            //se espiro la url
                            this.swift('tiempo excedido para cambio de contraseña',1)
                        }
                    }else{
                        //las contraseñas no coinciden
                        this.swift('La contraseñas no coinciden',1)
                    }
                }else{
                    //completa los campos
                    this.swift('Completa los campos',1)
                }
            },

            //actualizar gestiones

            async loadProfile(){
                //consulta de apirest
                let userConfiguraciones = await axios.get(`${this.server}/information/consultaConfiguraciones`, {

                    params: {token: localStorage.getItem('token')}}, 

                    {headers: {'Content-Type': 'application/json'}
                });

                //token expiro
                if(userConfiguraciones.data.message === "Error en validación"){
                    this.$router.push({path: '/app/profile'})
                }

                //carga de datos
                this.paiss = userConfiguraciones.data.pais
                this.ciudad = userConfiguraciones.data.ciudad
                this.nombre = userConfiguraciones.data.nombre
                this.apellido = userConfiguraciones.data.apellido
                this.correo = userConfiguraciones.data.correo
                this.referenciado = userConfiguraciones.data.referenciado
                this.roless = userConfiguraciones.data.role
                this.urlImagen = userConfiguraciones.data.url
                this.anchoImagen = userConfiguraciones.data.ancho
                this.alturaImagen = userConfiguraciones.data.alto
                this.izquierdaImagen = userConfiguraciones.data.izquierda
                this.topImagen = userConfiguraciones.data.arriba

                if(userConfiguraciones.data.url !== null){
                    this.bulFoto = true
                }else{
                    this.bulFoto = false
                }
            },

            //funcion encargada de quitar la caja de error

            quitarError(){
                this.bul = false
                this.mensaje = ''
                this.bulError = false
            },

            //funcion encargada de quitar la caja de correcto

            quitarHecho(){
                this.bul1 = false
                this.mensaje = ''
                this.bulLoader = true
                setTimeout(() => {
                    this.bulLoader = false
                    this.bulError = false
                    this.nuevaPassword =''
                    this.confirmaPassword = ''
                    this.abrirInterfazCambiarFoto = false
                    this.subirImagenBul = true
                    this.loadProfile();
                }, 2000);
            },

            //swift entre error y correcto

            swift(mensaje, caso){
                if(caso === 1){
                    this.bul = true
                    this.mensaje = mensaje
                    this.bulError = true
                    window.scroll(0,0)
                    setTimeout(() => {
                        this.quitarError()
                    }, 1500);
                }else{
                    this.bul1 = true
                    this.mensaje = mensaje
                    this.bulError = true
                    window.scroll(0,0)
                    setTimeout(() => {
                        this.quitarHecho()
                    }, 1500);
                }
            }
        },

        created () {
            this.loadProfile();
        },
        computed:{
            ...mapState(["token", "server"])
        }
    }
</script>

<style scoped>
    .x-nuevo{
        position: absolute;
        top:0px;
        width: 27.5px;
        height: 27.5px;
        background-repeat: no-repeat;
        background-image: url(../../assets/pictures/x-negro.svg);
        transition: all 0.5s;
        margin-top:135px;
        right: 12.5%;
        z-index: 10;
        cursor: pointer;
    }
    .carpeta-lista{
        width: 100px;
        height: 100px;
        background-image:url(../../assets/pictures/carpeta.svg) ;
        background-repeat: no-repeat;
        background-position:center ;
        background-size:cover ;
        animation-duration: 1s;
        animation-name: escalar ;
    }
    @keyframes escalar {
        0%{
            transform: scale(0);
        }
        100%{
            transform: scale(1);
        }
    }
    .margin-input{
        margin-left:75px;
    }
    .label3{
        font-family: Rubik;
        margin-top: 25px;   
        font-weight:bold ;
        margin-left:5px ;
    }
    .input3{
        border-radius:10px;
        border: #6464646b 2px solid;
        width: 100%;
        padding-left: 10px;
        padding-top: 3px;
        padding-bottom: 3px;
        margin-top:10px ;
    }
    .cuadrado-tamanio{
        position: absolute;
    }
    .imagen-cambio-perfil,.imagen-cambio-perfil2{
        position: absolute;
        z-index: 10;
        cursor: move;
        user-select: none;
    }
    .imagen-cambio-perfil2{
        cursor:default;
        background-image:url(../../assets/pictures/search-solid.svg);
        color: white;
    }
    .contenedor-errores,.contenedor-imagen{
        z-index: 10;
        top: 0px;
        left: 0px;
        position: fixed;
        width: 100%;
        height: 100vh;
        backdrop-filter:blur(2px);
        background:linear-gradient(240.98deg, rgba(0, 145, 142, 0) 45.71%, #00918E 100%),linear-gradient(290.11deg, rgba(17, 1, 51, 0.46) 0%, rgba(17, 1, 51, 0) 52.11%),linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6));
    }
    .contenedor-imagen{
        z-index: 10;
        height: calc(100% + 300px);
        position: absolute;
    }
    .contenedor-cambioPassword{
        width: 100%;
    }
    .imagen-perfil{
        position: absolute;
    }
    .titulo-foto{
        font-family: Rubik;
        font-weight: bold;
        margin-top:20px ;
        font-size: 45px;
        text-align: center;
        width: 100%;
    }
    .pie-de-foto,.pie-de-foto2{
        color:#297F87;
        margin-top:20px ;
        margin-bottom:0px ;
        cursor: pointer;
    }
    .pie-de-foto2{
        margin-left: 35px ;
    }
    .cruz-iniciar{
        width: 40%;
        height: 40%;
        cursor: pointer;
    }
    .circulo-perfil,.circulo-perfil2{
        position: absolute;
        width: 100%;
        height: 100%;
        background: #297F87;
        border-radius:50% ;
        overflow: hidden;
    }
    .loader{
        position: relative;
        width: 200px;
        height: 200px;
        perspective: 300px;
        top:300px;
    }
    .caja{
        position: absolute;
        width: 80%;
        height: 80%;
        background: #110133;
        border-radius:10%;
        left: 10%;
        top: 10%;
        animation: loaderA 3s ease-in-out infinite;
        perspective: 300px;
    }
    .caja2{
        width: 50%;
        height: 50%;
        background: #297F87;
        border-radius:50%;
        margin-left: 25%;
        margin-top: 25%;
        animation: loaderB 3s ease-in-out infinite;
    }
    .cuadrado-perfil,.cuadrado-perfil2,.cuadrado-perfil3{
        position: relative;
        width: 350px;
        height: 350px;
        margin-top:20px ;
        background: #CACACA;
        border-radius:20px;
    }
    .cuadrado-perfil2{
        margin-top:120px
    }
    .cuadrado-perfil3{
        position: relative;
        margin-top:180px
    }
    @keyframes loaderA {
        0%{
            transform: rotateZ(0deg) rotateY(0deg);
            border-radius:10% ;
        }
        50%{
            transform: rotateZ(180deg) rotateY(180deg);
            border-radius:50% ;
        }
        100%{
            transform: rotateZ(360deg) rotateY(360deg);
            border-radius:10% ;
        }
    }

    @keyframes loaderB {
        0%{
            transform: rotateZ(0deg) rotateY(0deg);
            border-radius:50% ;
        }
        50%{
            transform: rotateZ(180deg) rotateY(180deg);
            border-radius:10% ;
        }
        100%{
            transform: rotateZ(360deg) rotateY(360deg);
            border-radius:50% ;
        }
    }
    .nombre-gestion{
        color:#297F87;
        margin-left: 5px;
        margin-right:5px;
    }
    .contenedor-agregar-gestion{
        position: absolute;
        top:0px;
        left: 0px;
        width: 100%;
        backdrop-filter:blur(2px);
        background:linear-gradient(240.98deg, rgba(0, 145, 142, 0) 45.71%, #00918E 100%),linear-gradient(290.11deg, rgba(17, 1, 51, 0.46) 0%, rgba(17, 1, 51, 0) 52.11%),linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6));
    }
    .titulo-agregar-gestion{
        font-family: Rubik;
        font-weight: bold;
        margin-top:50px ;
        font-size: 45px;
        text-align: center;
    }
    .contenedor-file{
        position: relative;
        margin-top: 40px;
        display: inline-block;
        margin-bottom:80px ;
    }
    .button-configuracion,.button-configuracion2{
        width: 50%;
        height: 40px;
        border:none;
        border-radius:10px;
        background: #297F87;
        color: white;
        text-align: center;
        font-family: Rubik;
        font-size:20px ;
        margin-top:-30px ;
    }
    .button-configuracion2{
        margin-top: 55px;
    }
    .button-profile2{
        width: 100%;
        height: 40px;
        display: inline-block;
        border-radius: 10px;
    }
    .button-profile2::before{
        border:none;
        border-radius:10px;
        background: #297F87;
        color: white;
        text-align: center;
        font-family: Rubik;
        font-size:20px ;
        z-index: 10;
        content: 'Seleccionar';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        padding-top: 5px;
    }
    .label{
        font-family: Rubik;  
        font-weight:bold ;
        font-size:12 ;
        width: 100px;
        margin-left:100px;
    }
    .label2{
        font-family: Rubik;  
        font-weight:bold ;
        font-size:12 ;
        margin-left:35% ;
    }
    .contenedor-inputs{
        margin-top: 35px;
        width: 100%;
    }
    .contenedor-inputs2{
        margin-top: 35px;
        width: 100%;
    }
    .error-profile{
        margin: 1.5em 0em 1.5em 0em;
        padding-top: 1em;
        width: 700px;
        background-color: rgb(128, 18, 22,0.75);
        color: white;
        text-align: center;
        z-index: 10;
        font-family: Rubik;
        font-weight: bold;
        margin-top:350px;
    }
    .correcto-profile{
        margin: 1.5em 0em 1.5em 0em;
        padding-top: 1em;
        width: 700px;
        background-color: rgb(11, 114, 40,0.75);
        color: white;
        text-align: center;
        z-index: 10;
        font-family: Rubik;
        font-weight: bold;
        margin-top:350px ;
    }
    .input{
        border:none;
        border-bottom: #6464646b 2px solid;
        width: 50%;
        padding-left: 10px;
        padding-top: 3px;
        padding-bottom: 3px;
        margin-left:50px ;
        background: transparent;
    }
    .input2{
        border:none;
        border-bottom: #6464646b 2px solid;
        width: 30%;
        padding-left: 10px;
        padding-top: 3px;
        padding-bottom: 3px;
        background: transparent;
        margin-left:35% ;
    }
    .input:focus,.input2:focus{
        outline:none;
    } 
    .contenedor-form{
        position: relative;
        width: 85%;
        margin-top:0px;
        background: linear-gradient(169.26deg, rgba(255, 255, 255, 0.84) 0%, rgba(255, 255, 255, 0.15) 100%);
        height: 1100px;
        backdrop-filter:blur(2px);
        border-radius: 20px;
        box-shadow: 0px 1px 9px 9px rgba(0, 0, 0, 0.125), inset 0 0 9px white;
        transition: all 1s;
    }
    .contenedor-profile{
        position: relative;
        height: auto;
        width: 100%;
        padding-bottom:20px ;
        padding-top:100px ;
    }
    .contenedor-cards{
        position: absolute;
        perspective: 500px;
    }
    .card-estructura, .card-estructura-mas{
        margin-top:35px ;
        position: relative;
        height: 350px;
        perspective: 500px;
    }
    .card-estructura-mas{
        cursor: pointer;
    }
    .card-hover{
        position: absolute;
        background: transparent;
        height: 350px;
        width: 85%;
    }
    .card-hover > div{
        opacity: 0;
        transform: rotateY(180deg) rotateX(-2.5deg) scale(1);
        cursor: pointer;
    }
    .card-botones{
        position: absolute;
        background: linear-gradient(169.26deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);
        border-radius: 16px;
        padding: 10px;
        height: 350px;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.25), inset 0 0 9px white;
        cursor: pointer;
        width: 85%;
        opacity: 0;
        transition: all 1s;
    }
    .card-agregar{
        position: absolute;
        background: linear-gradient(169.26deg, rgba(255, 255, 255, 0.84) 0%, rgba(255, 255, 255, 0.15) 100%);
        border-radius: 16px;
        padding: 10px;
        height: 350px;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.25), inset 0 0 9px white;
        width: 85%;
        transition: all 1s;
    }
    .card-hover:hover ~ .card-agregar{
        transform: rotateY(180deg) rotateX(-2.5deg);
        transition: all 1s;
    }
    .card-hover:hover ~ .card-botones{
        transform: rotateY(180deg) rotateX(-2.5deg);
        opacity: 1;
        transition: all 1s;
    }
    .card-hover:hover > div{
        opacity: 1;
        transform: rotateY(0deg) rotateX(-2.5deg);
        transition: all 1s;
    }
    .fondo-degradado-profile{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 1;
        background:linear-gradient(290.11deg, rgba(17, 1, 51, 0.26) 0%, rgba(17, 1, 51, 0) 52.11%), linear-gradient(248.49deg, rgba(255, 255, 255, 0) 41.48%, rgba(41,127,135,0.5) 100.24%) ;
        z-index: -10;
        height: calc(100% + 300px);
    }
    .titulo-profile,.iniciar-gestion,.ultima-actualizacion,.titulo-cards,.numero-tickets{
        font-family: Rubik;
        font-weight: bold;
        margin-top:20px ;
        margin-bottom:50px ;
        font-size: 55px;
        text-align: center;
    }
    .iniciar-gestion,.titulo-cards{
        font-size:40px ;
        margin-top:20px ;
        margin-bottom:0px ;
    }
    .titulo-cards{
        width: 80%;
    }
    .ultima-actualizacion{
        font-size: 18px;
        margin-top:140px ;
        margin-bottom:0px ;
    }
    .numero-tickets{
        font-size: 60px;
        margin-top: 17.5px;
        margin-bottom:17.5px;
    }
    .parrafo{
        margin: 2px;
    }
    .papelera{
        background-repeat: no-repeat;
        background-image: url(../../assets/pictures/papelera.svg);
        width: 50px;
        height: 50px;
        transition: all 0.5s;
    }
    .flecha{
        background-repeat: no-repeat;
        background-image: url(../../assets/pictures/flecha.svg);
        width: 50px;
        height: 50px;
        margin-top:50px ;
        transition: all 0.5s;
    }
    .lapiz{
        background-repeat: no-repeat;
        background-image: url(../../assets/pictures/lapiz.svg);
        width: 50px;
        height: 50px;
        margin-top:50px ;
        transition: all 0.5s;
    }
</style>